import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-outdoor.png'
import MainMessage from '@/components/common/MainMessage'
import TypeList from '@/components/modules/Outdoor/List'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import RecruitLead from '@/components/modules/Recruit/Lead'

const OutdoorIndexPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const siteMeta = data.site.siteMetadata
      const company = data.company.edges[0].node
      return (
        <Root>
          <SEO location={props.location} title="アウトドア事業" description="株式会社フロントビジョンのアウトドア事業内容についての紹介。"/>
          <LD json={{
            '@type': 'Service',
            'name': 'アウトドア事業',
            'description': '株式会社フロントビジョンのアウトドア事業内容についての紹介。',
            'provider': LDEntity.Corporation(siteMeta, company)
          }}/>
          <Head>
            <PageTitle src={imgTitle}>アウトドア事業</PageTitle>
            <MainMessage
              body={(
                <Fragment>アウトドアブランド「MAAGZ」として、<br />製品の企画から製造・販売まで一貫して行っています。</Fragment>
              )}
            />
          </Head>
          <Main>
            <Row css={styles.TypeList}><TypeList /></Row>
            <Row css={styles.Breadcrumb}><Breadcrumb path={props.location.pathname}/></Row>
            <Row css={styles.Contact}><ContactLead /></Row>
            <Row css={styles.Recruit}><RecruitLead /></Row>
          </Main>
        </Root>
      )
    }}
  />
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div`
`

const Row = styled.div`
  position: relative;
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const styles = {
  TypeList: css`
    padding: 0 0 64px;
    @media ${mq.and(mobile)} {
      padding: 0 0 32px;
    }
  `,
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Recruit: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(OutdoorIndexPage)

const query = graphql`
  query OutdoorIndexPageQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
      }
    }
    company: allCompanyInfoYaml {
      edges {
        node {
          name
          legalName
          telephone
          email
          address {
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
        }
      }
    }
  }
`
